.App {
  text-align: center;
}


/* Style the scrollbar */
::-webkit-scrollbar {
  width: 8px;  /* Adjust the width of the scrollbar */
  height: 8px; /* Adjust the height of the scrollbar */
}

/* Style the track of the scrollbar */
::-webkit-scrollbar-track {
  background: #f1f1f1;  /* Track color */
  border-radius: 10px;
}

/* Style the handle of the scrollbar */
::-webkit-scrollbar-thumb {
  background: #8CA5BD;  /* Handle color */
  border-radius: 10px;
  border: 2px solid #f1f1f1;  /* Adds some padding around the handle */
}

/* Hover effect for the handle */
::-webkit-scrollbar-thumb:hover {
  background: #6E8A9D;  /* Handle color on hover */
}

/* Hide scrollbar arrows */
::-webkit-scrollbar-button {
  display: none;
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #8CA5BD #f1f1f1;
}

/* Optional: If you want the scrollbar to be thin and fancy for specific elements, e.g., a div with class 'fancy-scroll' */
.fancy-scroll {
  overflow-y: auto;
}

.fancy-scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.fancy-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.fancy-scroll::-webkit-scrollbar-thumb {
  background: #8CA5BD;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.fancy-scroll::-webkit-scrollbar-thumb:hover {
  background: #6E8A9D;
}

.fancy-scroll::-webkit-scrollbar-button {
  display: none;
}

.fancy-scroll {
  scrollbar-width: thin;
  scrollbar-color: #8CA5BD #f1f1f1;
}

